<template>
  <!-- 테마 상품 신청 모달 -->
  <div>
    <sweet-modal ref="modal" @close="onClose">
      <div class="modal-container" style="padding: 10px;">
        <div class="modal-title">
          <div class="title" style="font-weight:bold;font-size:24px;padding:0;margin-bottom: 16px;">
            <span v-if="!type">선주문 테마</span><span v-else>테마</span> 상품 이용 신청</div>
          <div class="content pc" style="text-align:left; padding: 0 60px;font-size: 12px;color: #777;margin-top:32px;margin-bottom: 32px;">
            <span v-if="!type">선주문 테마상품은 주무 후에 제작을 해드리는 상품으로 2~4주 정도 소요됩니다.
            신청하시면 런치팩에서 상담 전화를 드리고 결제 안내 메일을 드립니다.
            </span>
            <span v-else>신청 및 결제 후 1시간 이내에 바로 개설됩니다.</span>
          </div>
          <div class="content mobile" style="text-align:left; padding: 0;font-size: 12px;color: #777;margin-top:32px;margin-bottom: 12px;">
            <span v-if="!type">선주문 테마상품은 주무 후에 제작을 해드리는 상품으로 2~4주 정도 소요됩니다.
            신청하시면 런치팩에서 상담 전화를 드리고 결제 안내 메일을 드립니다.
            </span>
            <span v-else>신청 및 결제 후 1시간 이내에 바로 개설됩니다.</span>
          </div>
        </div>
        <div class="modal-content flex" style="overflow-y: unset;overflow-x:unset">
          <div class="modal-content-title">
            <div>서비스명*</div>
            <div>
              <div>서비스 아이디*</div>
            </div>
            <div style="height:24px">

            </div>
            <div>
              <div>관리자 이메일*</div>
            </div>
            <div>
              <div>관리자 성함*</div>
            </div>
            <div>
              <div>관리자 휴대전화 번호*</div>
            </div>
            <div>
              <div>선호 요금제*</div>
            </div>
            <div>
              <div>상담 가능 시간</div>
            </div>
          </div>

          <div class="modal-content-items-container">
            <div class="modal-content-items">
              <div class="modal-content-item">
                <div class="mobile"><input
                  type="text"
                  placeholder="서비스명*"
                  maxlength="50"
                  :value="params.service_name"
                  @input="e => params.service_name = e.target.value"
                ></div>
                <div class="pc"><input
                  type="text"
                  maxlength="50"
                  :value="params.service_name"
                  @input="e => params.service_name = e.target.value"
                ></div>
              </div>
              <div class="modal-content-item">
                <div class="mobile flex">
                  <input type="text"
                         placeholder="영문, 숫자만 가능"
                         maxlength="50"
                         v-model="params.service_id" @keyup="onKeyupServiceId"
                         style="width:50%;border-top-right-radius: 0;border-bottom-right-radius: 0;">
                  <input type="text"
                         value=".launchpack.co.kr"
                         style="width:50%;border-left: 0;border-top-left-radius: 0;border-bottom-left-radius: 0;color:#777;background-color:#eee"
                         :disabled="false">
                </div>
                <div class="pc flex" style="height: 32px;">
                  <input
                    type="text"
                    maxlength="50"
                    placeholder="영문, 숫자만 가"
                    v-model="params.service_id" @keyup="onKeyupServiceId"
                    style="width:50%;border-top-right-radius: 0;border-bottom-right-radius: 0;">
                  <input type="text"
                         value=".launchpack.co.kr"
                         style="width:50%;border-left: 0;border-top-left-radius: 0;border-bottom-left-radius: 0;color:#777;background-color:#eee"
                         :disabled="false">
                </div>
              </div>
              <div style="text-align:left;font-size:13px;margin-bottom:20px;color:#989898">*도메인은 개설 후 변경 가능합니다</div>
              <div class="modal-content-item">
                <div class="mobile"><input
                  type="email"
                  placeholder="관리자 이메일*"
                  maxlength="50"
                  :value="params.email"
                  @input="e => params.email = e.target.value"
                  disabled
                ></div>
                <div class="pc"><input
                  type="email"
                  maxlength="50"
                  placeholder="관리자 이메일*"
                  :value="params.email"
                  @input="e => params.email = e.target.value"
                  disabled
                ></div>
              </div>
              <div class="modal-content-item">
                <div class="mobile"><input
                  type="text"
                  maxlength="20"
                  placeholder="관리자 성함*"
                  :value="params.name"
                  @input="e => params.name = e.target.value"
                ></div>
                <div class="pc"><input
                  type="text"
                  maxlength="20"
                  :value="params.name"
                  @input="e => params.name = e.target.value"
                ></div>
              </div>
              <div class="modal-content-item">
                <div class="mobile"><input
                  type="phone"
                  :value="params.phone"
                  @input="e => params.phone = e.target.value"
                  placeholder="관리자 휴대전화 번호*"
                  maxlength="13"
                  @keyup="onKeyup"
                ></div>
                <div class="pc"><input
                  type="phone"
                  :value="params.phone"
                  @input="e => params.phone = e.target.value"
                  maxlength="13"
                  @keyup="onKeyup"
                ></div>
              </div>
              <div>
                <div class="mobile" style="height: 32px; margin-top:12px;text-align:left">
                  <lp-drop-down placeholder="요금제"
                                :val.sync="params.price"
                                @selected="selectOption"
                                :height="32"
                                :options="option"
                  ></lp-drop-down>
                </div>
                <div class="pc" style="height: 32px;text-align:left">
                  <lp-drop-down placeholder="요금제"
                                :val.sync="params.price"
                                @selected="selectOption"
                                :height="32"
                                :options="option"
                  ></lp-drop-down>
                </div>
              </div>
              <!--<div style="text-align:left;font-size:12px;height:50px;color:#989898;">*선택하신 요금제로 결제 안내 메일을 드립니다.<br/>지금 결제 되지 않습니다.</div>-->

              <div class="modal-content-item" style="margin-top:20px">
                <div class="mobile"><input
                  type="text"
                  :value="params.counsel"
                  @input="e => params.counsel = e.target.value"
                  placeholder="상담시간 ex) 11월 30일 18시부터 가능"
                  maxlength="13"
                  @keyup="onKeyup"
                ></div>
                <div class="pc"><input
                  type="text"
                  :value="params.counsel"
                  @input="e => params.counsel = e.target.value"
                  placeholder="ex) 11월 30일 18시부터 가능"
                  maxlength="13"
                  @keyup="onKeyup"
                ></div>
              </div>

              <!-- 개인정보 체크박스 -->
              <div class="modal-content-item flex"
                   style="align-items:center;justify-content: space-between;margin-top:12px;margin-bottom:20px">
                <lp-check-bool style="margin-top:4px"
                  :checkedVal.sync="privacy" label="구매 조건 확인 및 결제 진행 동의"></lp-check-bool>
                <!--<div class="lp-privacy-look"
                     @click="clickAll('limit')">전체보기</div>-->
              </div>

                    <!--&lt;!&ndash; 개인정보 수집 &ndash;&gt;
              <div class="modal-content" v-show="isShow" style="margin-bottom: 20px;">
                <div class="lp-privacy-wrap">
                  <strong>개인정보 수집/이용목적</strong>
                  <p>1) 고객지원: 각종 문의에 대한 지원<br>
                    2) 마케팅 및 광고에 활용: 신규 서비스 개발과 이벤트 행사에 따른 정보 전달 및 맞춤 서비스 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악 또는
                    회원의 서비스 이용에 대한 통계</p>
                  <br>
                  <strong>수집하는 개인정보의 항목</strong>
                  <p>성명, 연락처, 이메일<br>
                    또한, 서비스 이용과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.<br>
                    − IP주소, OS/브라우저 종류, 방문 일시, 서비스 이용기록, 접속로그, 쿠키</p>
                  <br>
                  <strong>개인정보의 보유 및 이용기간</strong>
                  <p>보존 기간은 3년이며, 정보 제공자가 삭제를 요청할 경우 즉시 파기합니다.<br>
                    고객님의 정보는 개인정보 보호법에 따라 보호되며 위의 사항 외에 별도로 사용하지 않을 것을 약속드립니다.</p>
                </div>
              </div>-->

              <!--<div class="modal-content-item lp-request-simple-desc" style="margin-bottom: 40px">
                <div>신청한 메일주소로 별도의 가입 절차 없이 회원가입 됩니다.<br>작성한 이메일이 아이디가 되어 추후 로그인하여 내 서비스 현황을 볼 수 있게 됩니다.</div>
              </div>-->

              <div class="flex div-request-button" style="justify-content: center">
                <div class="btn request-button" @click="contactUs">신청하기</div>
              </div>

            </div>
          </div>
        </div>


      </div>
    </sweet-modal>

    <billing-pc v-if="isBillingPc && visiblePG"></billing-pc>
    <billing v-if="!isBillingPc && visiblePG"></billing>
  </div>
</template>

<script>
  import {SweetModal} from 'sweet-modal-vue';
  import LpDropDown from "../LpDropDown";
  import LpCheckBool from "../module/LpCheckBool";
  import BillingPc from "../page/BillingPc";
  import Billing from "../page/Billing";

  export default {
    props: {
      is_open: {
        type: Boolean,
        required: false,
      },
      title: {
        type: String,
        required: false
      },
      type: {
        type: String,
        required: false
      }
    },
    name: "lp-theme-request-modal",
    components: {
      Billing,
      BillingPc,
      LpCheckBool,
      LpDropDown,
      SweetModal
    },
    data() {
      return {
        privacy: false,
        isShow: false,
        params: {
          user_id: '',
          product_id: '',
          service_name: '',
          service_id: '',
          counsel: '',
          name: '',
          email: '',
          phone: '',
          price: '',
          type: '',
          option_id: '',
          option_type: 0
        },
        option: [],
        isBillingPc: false,
        visiblePG: false
      /*{ val: '580000', label: '1개월 (매월 정기결제) / 580,000원' },
      { val: '1500000', label: '3개월 (5% 할인) / 1,500,000원' },
      { val: '3000000', label: '6개월 (10% 할인) / 3,000,000원'},
      { val: '6000000',label: '1년 (20% 할인) / 6,000,000원'},
      { val: '8000000', label: '평생 / 8,000,000원(150,000원/매월 정기결제)'}*/
      }
    },
    created() {
      if (this.type) this.params.type = this.type;
      this.getOption();

      this.params.product_id = this.$route.query.id;

      this.initParams();

      window.addEventListener('message', e => {
        console.log(e.data); // { result: 'close' }
        if(!e.data) return;
        if(e.data.result==='close') {
          this.visiblePG=false;
        }
      });
    },
    watch: {
      is_open(n) {
        if (n) this.$refs.modal.open();
        else this.$refs.modal.close();
      },
      $route(){
        this.getOption();
      },
      title (n) {
        this.params.title = n;
      },
      type (n) {
        this.params.type = n;
      }
    },
    methods: {
      onClose() {
        this.visiblePG = false;
        this.$emit('update:is_open', false);
      },
      onKeyupServiceId: function (e) {
        // if(/[0-9a-z]/.test(e.target.value[e.target.value.length-1])) {
        //   this.params.service_id = e.target.value;
        // }

        // eslint-disable-next-line no-useless-escape
        let regExp = /[\{\}\[\]\/?_.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi;
        // 특수문자 예외처리
        if (regExp.test(e.target.value)) {
          this.params.service_id = this.params.service_id.replace(regExp, '');
        } else if (e.target.value.length > 0 && /[a-zA-Z]/.test(e.target.value[0])) {
          this.params.service_id = e.target.value;
        } else {
          this.params.service_id = '';
        }
        this.params.service_id = this.params.service_id.toLowerCase();
      },
      selectOption(res) {
        this.params.option_id = res.id;
        this.params.option_type = res.type;
      },
      initParams () {
        if(this.$store.getters.user.name) {
          let user = this.cloneItem(this.$store.getters.user)
          let user_id = '';
          if (user.user_id > 0) user_id = user.user_id;
          this.params.user_id = user_id;
          this.params.name = user.name;
          this.params.email = user.email;
          this.params.phone = user.phone;
          this.params.title = this.title;
        }
      },
      clickAll(type) {
        if(type==='limit') {
          window.open('/limit');
        }
      },
      getOption(){
        this.$axios.get(`public/launchpack/theme/${this.$route.query.id}/option`).then(res=>{
          if(res.status===200) {
            this.option = res.data;
          }
        })
      },
      onKeyup() {
        this.params.phone = this.autoHyphenPhone(this.params.phone);
      },
      onChange(event, slick, currentSlide) {
        this.currentSlide = currentSlide;
      },
      onModal(type) {
        if (type === 'type_month') this.params.price = '580000';
        if (type === 'type_opend') this.params.price = '10000000';
        this.$refs.modal.open();
      },
      async contactUs() {
        let valid = await this.isValid();
        if (valid === false) return;

        if(this.$store.getters.isLogin) {
          this.$store.commit('setBasket', this.params);
          this.isBillingPc = !this.isMobile;
          this.visiblePG = true;
        }
        else {
          this.toast('로그인 후 신청하시기 바랍니다.');
          this.$router.replace('signin');
        }
        /*this.$store.commit('setLoading', true);
        //let url = 'http://13.209.97.215:1111/public/launchpack/inquiry';
        let url = 'public/launchpack/theme/request';
        let params = this.cloneItem(this.params);


        this.$axios.post(url, params).then(res => {
          if (res.status === 200) {
            console.log(res);
            this.$refs.modal.close();
            this.toast('신청이 완료되었습니다.');
            this.$store.commit('setLoading', false);
            this.$emit('update:is_open', false);
            // modal text reset
            let keys = Object.keys(this.params);
            for (let i = 0; i < keys.length; i++) {
              this.params[keys[i]] = '';
            }
            this.privacy = false;

            if(this.$store.getters.user.name){
              this.$router.push('/mypage/themerequest');
            }
          }
        }).catch(err => {
          this.$store.commit('setLoading', false);
          if (!err.response.data.message)
            console.log(err.response.data.detail);
          else
            console.log(err.response.data.message);
        });*/
      },
      async isValid() {
        let valid = true;
        let keys = Object.keys(this.params);
        for (let i = 0; i < keys.length; i++) {
          let val = this.params[keys[i]];
          if (['counsel', 'user_id', 'type', 'title'].indexOf(keys[i]) > -1) {
            continue;
          }
          if (this.isError(keys[i], val)) {
            console.log(keys[i])
            valid = false;
            break;
          }
          if (!this.privacy) {
            this.toast("구매진행 동의에 체크를 하셔야 결제가 진행됩니다.");
            valid = false;
            break;
          }
          if(keys[i] === 'service_id') {
            let res = await this.$axios.get('public/launchpack/service/validate?service_key='+val);
            if(res.data.result === 'already' || res.data.result === 'already_temporary') {
              this.toast('이미 등록된 서비스 아이디가 있습니다.');
              valid = false;
              break;
            }
          }
        }

        return valid;
      },
      isError(key, val) {
        let error = false;
        if (val === '' || val === ' ')
          error = true;

        let msg = '';
        switch (key) {
          case 'service_name':
            msg = '서비스명을';
            break;
          case 'service_desc':
            msg = '서비스 한줄 소개를';
            break;
          case 'service_id':
            msg = '서비스 아이디를';
            break;
          case 'content':
            msg = '문의 내용을';
            break;
          case 'price':
            msg = '요금제를';
            break;
          case 'name':
            msg = '대표자명을';
            break;
          case 'email':
            msg = '이메일을';
            break;
          case 'phone':
            msg = '연락처를';
            break;
        }
        if (msg !== '' && error)
          this.toast(msg + ' 입력해주세요.');

        return error;
      },
      toast(msg) {
        this.$root.$emit('toast', msg);
      },
      onClick() {
        this.isShow ? this.isShow = false : this.isShow = true;
      },
    }
  }
</script>

<style scoped>
  .title{
    font-size: 24px;
    font-weight: 500;
    margin-bottom:23px;
  }
  .content{
    font-size:12px;
    color:#a0a0a0;
  }

  .request-button {
    background-color: #FF6600;
    color: #fff;
    border-radius: 3px;
    width: 248px;
    height: 48px;
    text-align: center;
    font-size: 14px;
    line-height: 46px;
    cursor: pointer;
    margin-bottom: 40px;
  }

  .lp-request-simple-desc {
    text-align: left;
    font-size: 12px;
    color: #a0a0a0;
    margin-left: -150px;
  }
  .div-request-button {
    margin-left: -200px;
  }

  .lp-privacy-look {
    color:#ff6600;
    font-size:14px;
    cursor:pointer;
  }

  .modal-container {
    max-height: 80vh;
  }

  @media (max-width: 600px) {
    .lp-request-simple-desc, .div-request-button {
      margin-left: 0;
    }
  }

</style>
