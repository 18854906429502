<template>
  <div class="lp-check-bool-container">
    <div :class="{ 'checked-box' : !!isChecked, 'not-checked-box' : !isChecked }"
         :style="checkStyle"
         @click.stop.prevent="clickCheck">
      <i v-if="!!isChecked" class="material-icons checked-icon" @click.stop.prevent="clickCheck">check</i>
    </div>
    <div :style="labelStyle" @click.stop.prevent="clickCheck">{{ label }}</div>
  </div>
</template>

<script>
  export default {
    props: {
      checkedVal: {
        type: Boolean
      },
      label: {
        type: String
      },
      labelStyle: {
        type: Object,
        default: function () {
          return {
            fontSize: '0.9rem',
            lineHeight: '17px',
            color: '#555'
          }
        }
      }
    },
    created() {
      this.isChecked = this.checkedVal;
    },
    data() {
      return {
        isChecked: false
      }
    },
    computed: {
      checkStyle() {
        let style = {}
        if(this.label) {
          style.marginRight = 8;
        }
        else style.marginRight = 0;

        return style
      }
    },
    methods: {
      clickCheck() {
        if ( this.isChecked === false ) {
          this.isChecked = true;
        }
        else if ( this.isChecked === true ) {
          this.isChecked = false;
        }
        this.$emit('update:checkedVal', this.isChecked);
        this.$emit('onCheck', this.isChecked);
        this.$emit('dataCheck', {id:this.label, value:this.isChecked});
      }
    },
    watch: {
      checkedVal(n) {
        this.isChecked = n;
      }
    }
  }
</script>

<style scoped>
  .lp-check-bool-container{
    display: flex;
    align-items: flex-end;
    cursor: pointer;
  }

  .checked-box {
    position: relative;
    width: 16px;
    height: 16px;
    border: 2px solid #ff6600;
    border-radius: 3px;
    margin-right: 8px !important;
    text-align: center;
  }
  .not-checked-box {
    position: relative;
    width: 16px;
    height: 16px;
    border: 1px solid #dddddd;
    border-radius: 3px;
    margin-right: 8px !important;
    text-align: center;
  }

  .checked-box {
    background-color: #ff6600;
  }

  .not-checked-box {
    background-color: #fff;
  }

  .checked-icon {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 0;
  }

  @media (max-width: 600px) {
    .modal-content-item div {
      margin: 0;
    }
  }

</style>
