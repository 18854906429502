<template>
  <!-- 주문제작 신청 모달 -->
  <div>
    <div class="modal-container" style="padding: 10px;">
      <div class="modal-title">
        <div class="title" style="font-weight: bold; font-size:24px;padding:0;margin-bottom:16px">주문제작 신청서</div>
        <div class="content pc" style="text-align:left; padding: 0 60px;font-size: 12px;color: #777;">
          신청하시면 런치팩에서 상담전화를 드리고 견적 안내 메일을 드립니다.</div>
        <div class="content mobile" style="text-align:left; padding: 0; font-size: 12px;color: #777;">
          신청하시면 런치팩에서 상담전화를 드리고 견적 안내 메일을 드립니다.</div>
      </div>
      <div class="modal-content flex" style="overflow-y: unset;overflow-x:unset">
        <div class="modal-content-title">
          <div>서비스 이름*</div>
          <div id="modal-content-title-second-cell">
            <div>서비스 소개*</div>
          </div>
          <!--<div>
            <div>요금제 선택</div>
          </div>-->
          <div>
            <div>희망 소요기간</div>
          </div>
          <div>
            <div>예산</div>
          </div>
          <div>
            <div>소개 자료</div>
          </div>
          <div>
            <div>신청자 성함*</div>
          </div>
          <div>
            <div>신청자 휴대전화 번호*</div>
          </div>
          <div>
            <div>신청자 이메일*</div>
          </div>
          <div>
            <div>상담 가능 시간</div>
          </div>
        </div>
        <div class="modal-content-items-container">
          <div class="modal-content-items">
            <div class="modal-content-item">
              <div class="mobile"><input
                type="text"
                placeholder="서비스명*"
                maxlength="50"
                :value="params.service_name"
                @input="e => params.service_name = e.target.value"
              ></div>
              <div class="pc"><input
                type="text"
                maxlength="50"
                :value="params.service_name"
                @input="e => params.service_name = e.target.value"
              ></div>
            </div>
            <div id="modal-content-item-second-cell">
              <div class="mobile"><textarea
                type="text"
                placeholder="서비스 소개*"
                :value="params.content"
                @input="e => params.content = e.target.value"
              ></textarea></div>
              <div class="pc"><textarea
                type="text"
                :value="params.content"
                @input="e => params.content = e.target.value"
              ></textarea></div>
            </div>
            <div class="modal-content-item">
              <div class="mobile">
                <input type="text"
                       placeholder="희망 소요 기간"
                       :value="params.hope_date"
                       @input="e=> params.hope_date = e.target.value">
              </div>
              <div class="pc">
                <input type="text"
                       :value="params.hope_date"
                       @input="e=> params.hope_date = e.target.value">
              </div>
            </div>
            <div class="modal-content-item">
              <div class="mobile"><input
                type="number"
                placeholder="예산"
                :value="params.account"
                @input="e => setNumber(e, 10)"
              ></div>
              <div class="pc"><input
                type="number"
                :value="params.account"
                @input="e => setNumber(e, 10)"
              ></div>
            </div>
            <div>
              <input-file class="mobile" style="margin-top:10px" :files.sync="params.file"></input-file>
              <input-file class="pc" style="margin-bottom:16px" :files.sync="params.file"></input-file>
            </div>
            <div class="modal-content-item">
              <div class="mobile"><input
                type="text"
                maxlength="20"
                placeholder="대표자명*"
                :value="params.name"
                @input="e => params.name = e.target.value"
              ></div>
              <div class="pc"><input
                type="text"
                maxlength="20"
                :value="params.name"
                @input="e => params.name = e.target.value"
              ></div>
            </div>
            <div class="modal-content-item">
              <div class="mobile"><input
                type="phone"
                :value="params.phone"
                @input="e => params.phone = e.target.value"
                placeholder="연락처*"
                maxlength="13"
                @keyup="onKeyup"
              ></div>
              <div class="pc"><input
                type="phone"
                :value="params.phone"
                @input="e => params.phone = e.target.value"
                maxlength="13"
                @keyup="onKeyup"
              ></div>
            </div>
            <div class="modal-content-item">
              <div class="mobile"><input
                type="email"
                placeholder="이메일*"
                maxlength="50"
                :value="params.email"
                @input="e => params.email = e.target.value"
              ></div>
              <div class="pc"><input
                type="email"
                maxlength="50"
                :value="params.email"
                @input="e => params.email = e.target.value"
              ></div>
            </div>

            <div class="modal-content-item">
              <div class="mobile"><input
                type="text"
                :value="params.counsel"
                @input="e => params.counsel = e.target.value"
                placeholder="상담시간 ex) 11월 30일 18시부터 가능"
                maxlength="13"
                @keyup="onKeyup"
              ></div>
              <div class="pc"><input
                type="text"
                :value="params.counsel"
                @input="e => params.counsel = e.target.value"
                maxlength="13"
                placeholder="ex) 11월 30일 18시부터 가능"
                @keyup="onKeyup"
              ></div>
            </div>

            <!-- 개인정보 체크박스 -->
            <div class="modal-content-item flex"
                 style="align-items:center;justify-content: space-between">
              <lp-check-bool style="margin-top:4px"
                             :checkedVal.sync="privacy" label="개인정보 취급방침 동의"></lp-check-bool>
              <div class="lp-privacy-look"
                   @click="clickAll('limit')">전체보기</div>
            </div>

            <!-- 개인정보 수집 -->
            <!--<div class="modal-content" v-show="isShow" style="margin-bottom: 20px;">
              <div class="lp-privacy-wrap">
                <strong>개인정보 수집/이용목적</strong>
                <p>1) 고객지원: 각종 문의에 대한 지원<br>
                  2) 마케팅 및 광고에 활용: 신규 서비스 개발과 이벤트 행사에 따른 정보 전달 및 맞춤 서비스 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계</p>
                <br>
                <strong>수집하는 개인정보의 항목</strong>
                <p>성명, 연락처, 이메일<br>
                  또한, 서비스 이용과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.<br>
                  − IP주소, OS/브라우저 종류, 방문 일시, 서비스 이용기록, 접속로그, 쿠키</p>
                <br>
                <strong>개인정보의 보유 및 이용기간</strong>
                <p>보존 기간은 3년이며, 정보 제공자가 삭제를 요청할 경우 즉시 파기합니다.<br>
                  고객님의 정보는 개인정보 보호법에 따라 보호되며 위의 사항 외에 별도로 사용하지 않을 것을 약속드립니다.</p>
              </div>
            </div>-->

            <div class="modal-content-item lp-request-simple-desc" style="margin-bottom: 40px">
              <div>신청한 메일주소로 별도의 가입 절차 없이 회원가입 됩니다.<br>작성한 이메일이 아이디가 되어 추후 로그인하여 내 서비스 현황을 볼 수 있게 됩니다.</div>
            </div>

            <div class="flex div-request-button" style="justify-content: center">
              <div class="btn request-button" @click="contactUs">신청하기</div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import InputFile from "../module/oldInputFile";
  import LpCheckBool from "../module/LpCheckBool";

  export default {
    props: [],
    name: "lp-service-inquiry-modal",
    components: {
      LpCheckBool,
      InputFile
    },
    data() {
      return {
        params: {
          user_id: '',
          service_name: '',
          account: 0,
          file: [],
          counsel: '',
          content: '',
          name: '',
          email: '',
          phone: '',
          hope_date: '',
        },
        privacy: false,
        isShow: false,
      }
    },
    created() {
      if(this.$store.getters.user.name) {
        let user = this.cloneItem(this.$store.getters.user)
        let user_id = '';
        if (user.user_id > 0) user_id = user.user_id;
        this.params.user_id = user_id;
        this.params.name = user.name;
        this.params.email = user.email;
        this.params.phone = user.phone;
      }
    },
    watch: {

    },
    methods: {
      clickAll(type) {
        if(type==='limit') {
          window.open('/limit');
        }
      },
      setNumber(e, max_length){
        if( e.target.value.length > max_length ){
          this.params.account = parseInt(e.target.value.substr(0,max_length));
        }
        else if( e.target.value.length === 0 ) {
          this.params.account = 0;
        }
        else {
          this.params.account = parseInt(e.target.value);
        }
      },
      initData() {
        this.params = {
          service_name: '',
          account: '',
          file: [],
          counsel: '',
          content: '',
          name: '',
          email: '',
          phone: '',
          hope_date: ''
        },
          this.privacy = false;
        this.isShow = false;
      },
      contactUs () {
        if(this.isValid() === false) return;

        this.$store.commit('setLoading', true);
        let url = 'public/launchpack/inquiry';
        let params = this.cloneItem(this.params);
        params.file = this.params.file[0];
        this.$axios.post(url, params).then(res => {
          if (res.status === 200) {
            this.setGa('주문제작 신청 완료', '응답', '주문제작 신청 완료');
            this.initData();
            this.$emit('closeModal');
            this.toast('신청이 완료되었습니다.');
            this.$store.commit('setLoading', false);
            // modal text reset
            let keys = Object.keys(this.params);
            for(let i=0; i<keys.length; i++) {
              this.params[keys[i]] = '';
            }
            this.privacy = false;

            if(this.$store.getters.user.name){
              this.$router.push('/mypage/inquiry');
            }
          }
        }).catch(err => {
          this.$store.commit('setLoading', false);
          if(!err.response.data.message){
            this.toast(err.response.data.detail);
            console.log(err.response.data.detail);
          }
          else{
            this.toast(err.response.data.detail);
            console.log(err.response.data.message);
          }
        });
      },
      isValid() {
        let valid = true;
        let keys = Object.keys(this.params);
        for(let i=0; i<keys.length; i++) {
          let val = this.params[keys[i]];
          if(['account', 'counsel', 'hope_date', 'file', 'user_id'].indexOf(keys[i])>-1){
            continue;
          }
          if(this.isError(keys[i], val)) {
            valid = false;
            break;
          }
          if(!this.privacy) {
            this.toast('개인정보취급방침 약관에 동의해주세요.');
            valid = false;
            break;
          }
        }
        return valid;
      },
      isError(key, val) {
        let error = false;
        let patternEmail = /[0-9a-zA-Z][_0-9a-zA-Z-]*@[_0-9a-zA-Z-]+(\.[_0-9a-zA-Z-]+){1,2}$/;    //이메일

        if(val === '' || val === ' ') {
          error = true;

          let msg = '';
          switch(key) {
            case 'service_name':
              msg = '서비스명을';
              break;
            case 'service_desc':
              msg = '서비스 한줄 소개를';
              break;
            case 'content':
              msg = '문의 내용을';
              break;
            case 'product':
              msg = '요금제를';
              break;
            case 'name':
              msg = '대표자명을';
              break;
            case 'email':
              msg = '이메일을';
              break;
            case 'phone':
              msg = '연락처를';
              break;
          }
          if(msg!=='' && error)
            this.toast(msg + ' 입력해주세요.');
        } else if(key === 'email' && !patternEmail.test(val)) {
          this.toast('올바른 이메일 형식이 아닙니다.');
          error = true;
        }

        return error;
      },
      // 날짜 선택
      selDateMin(val) {
        this.params.hope_date = val;
      },
      onKeyup() {
        this.params.phone = this.autoHyphenPhone(this.params.phone);
      },
      onClick () {
        this.isShow?this.isShow=false:this.isShow=true;
      },
    }
  }
</script>

<style scoped>
  .request-button {
    background-color: #FF6600;
    color: #fff;
    border-radius: 3px;
    width: 248px;
    height: 48px;
    text-align: center;
    font-size: 14px;
    line-height: 46px;
    cursor: pointer;
    margin-bottom: 40px;
  }

  .lp-request-simple-desc {
    text-align: left;
    font-size: 12px;
    color: #a0a0a0;
    margin-left: -150px;
  }

  .div-request-button {
    margin-left: -200px;
  }

  .lp-privacy-look {
    color:#ff6600;
    font-size:14px;
    cursor:pointer;
  }

  .modal-container {
    max-height: 80vh;
  }

  @media (max-width: 600px) {
    .lp-request-simple-desc, .div-request-button {
      margin-left: 0;
    }
  }
</style>
