<template>
  <div></div>
</template>

<script>
  import sha256 from 'js-sha256';
  export default {
    name: "billing",
    created() {
      let pg = 'https://plugin.launchpack.co.kr';
      let basket = this.$store.getters.basket;
      let orderid = `launchpack_${ this.dayjs().format('YYYYMMDDHHmmss') + this.dayjs().millisecond() }`;

      this.requests.buyername = basket.name;
      this.requests.buyertel = basket.phone;
      this.requests.buyeremail = basket.email;
      this.requests.goodname = basket.title;
      this.requests.price = basket.price;

      let p_noti = {
        buyername: basket.name,
        buyertel: basket.phone,
        buyeremail: basket.email,
        goodname: basket.title,
        price: basket.price
      };
      this.requests.p_noti = JSON.stringify(p_noti);
      this.requests.returnurl = pg+'/php/inicis/inicis/pay/lpMobilePayBillReturn.php?redirect_url=https://launchpack.co.kr/service_account';
      this.requests.period = basket.option_type === 1 ? 'M2' : '';

      let form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('action', 'https://inilite.inicis.com/inibill/inibill_card.jsp');

      let timestamp = this.dayjs().format('YYYYMMDDHHmmss');

      let keys = Object.keys(this.requests);
      for(let i=0; i<keys.length; i++) {
        let key = keys[i];
        if(key === 'orderid') {
          this.input(form, key, orderid);
        }
        if(key === 'timestamp') {
          this.input(form, key, timestamp);
        }
        else if(key === 'hashdata') {
          let hashdata = sha256.sha256(this.requests.mid + orderid + timestamp + this.requests.merchantkey);
          this.input(form, key, hashdata);
        }
        else
          this.input(form, key, this.requests[key]);
      }


      document.body.appendChild(form);
      form.submit();
    },
    data() {
      return {
        requests: {
          merchantkey: 'UTVHRTRPaEV4YUFYNTBrWGFDZW9hdz09',
          mid: 'launchpack',
          // merchantkey: 'b09LVzhuTGZVaEY1WmJoQnZzdXpRdz09',
          // mid: 'INIBillTst',
          buyername: '이남호',
          goodname: '런치팩 솔루션 월 요금제',
          price: '580000',
          orderid: '',
          returnurl: '',
          merchantreserved: '',
          timestamp: '',
          period: 'M2',
          period_custom: '',
          hashdata: ''
        }
      }
    },
    methods: {
      input(form, name, value) {
        let input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', name);
        input.setAttribute('value', value);
        form.appendChild(input);
      }
    }
  }
</script>

<style scoped>

</style>
