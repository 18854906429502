import axios from 'axios'

const userAxios = axios.create({
  headers: {
    common: {}
  }
});

export default {
  data() {
    return {
      request: {
        user: userAxios,
      },
    };
  },


  created() {
    // Admin base URL 설정.
    this.request.user.defaults.baseURL = `${process.env.VUE_APP_MASTER_BACKEND_URL}/user/${this.$store.getters.user.user_id}`;

    // 인증 설정.
    if (this.$store.getters.user.token !== '') {
      const auth = 'Token ' + this.$store.getters.user.token;
      this.setHeader(this.request.user, 'Authorization', auth);
    }

    this.request.user.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response.status === 401) {
        this.logout(); // 로그아웃 메서드 호출
      }
      return Promise.reject(error);
    });
  },

  methods: {
    setHeader(request, key, value) {
      request.defaults.headers.common[key] = value;
    },
    // 로그아웃
    async logout() {
      await this.request.user.post(`${process.env.VUE_APP_MASTER_BACKEND_URL}/auth/user/logout`)
        .then(() => {
          this.$store.commit('logOut');
          delete this.request.user.defaults.headers.common['Authorization'];
          this.$router.replace('/home');
        }).catch(err => {
          if(err.response.data.detail === undefined)
            this.toast(err.response.data.message);
          else
            this.toast(err.response.data.detail);
        });
    }
  }
}
